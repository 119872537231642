import React from 'react';
import Layout from '@components/Core/Layout';

const FourOhFour = () => {
  return (
    <div className="flex-grow pt-100px flex flex-col items-center justify-center py-10 px-10 space-y-6">
      <div className="flex flex-col items-center space-y-6">
        <img
          className="w-180px h-180px object-contain object-center flex-shrink-0"
          src="/images/404.svg"
          alt="404"
        />
        <span className="text-5xl font-barlow-condensed font-bold">Page not found</span>
        <p className="font-poppins text-sm text-neutral-250 text-center">
          Seems like the page you’re trying to reach cannot
          <br /> be found
        </p>
      </div>
    </div>
  );
};

FourOhFour.getLayout = (page: any) => {
  return <Layout>{page}</Layout>;
};

export default FourOhFour;
